import React from 'react'
import '../Style/Login.css'
import '../Style/BodyStyle.css'
import { useState } from 'react'
import axios from 'axios'
import {baseurl} from '../Urls/Urls'
import { useNavigate } from 'react-router-dom'
import logo from '../Images/guruvayurappan.png'

function Loginpage() {

  const navigate=useNavigate()  
const [login, setlogin] = useState(true)
const [otp, setotp] = useState(false)
const [phoneno, setphonno] = useState('')


//Number Checking
const Checknumber=(e)=>{
  //  console.log(phoneno)
   try {
        axios.get(`${baseurl}/Management?mnum=${phoneno}`)
        .then((r)=>{
       //     console.log(r.data)
            if(r.data)
            {
           
             localStorage.setItem('IsView',r.data[0].Dview) //seting Isview Local Storage
             localStorage.setItem('Mname',r.data[0].Name) //seting name local storage
             localStorage.setItem('Mid',r.data[0].Id)
            
            if(localStorage.getItem('IsView') == 1)
            {  
                axios.post(`${baseurl}/Login?phone=${phoneno}`)
                .then((r)=>{
                 
                    if(r.data[0].Error==0)
                    {
                        setlogin(false)
                        setotp(true)
                        navigate('/Dashboard')
                    }
                })
               
            }
            else{
                navigate('/Home')
            }
            }
            else
            {
                alert("enterd wrong number..!")
            }
        })
    } catch (error) {
       console.log("error when check number"+error) 
    }
}


  return (

    <>

    <div className='logbody'>
    
  {

     login&& <div class="container create-formcon" >
    
      <div class="row justify-content-center" style={{ maxHeight: '444px' }}>
         <div class="col-lg-4 col-md-7 login-otp-height">
        
           <div class="loginotp-holder">
           <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
    <img src={logo} style={{height:'20vh'}} alt='ib' ></img>
    </div>
           <h3>Login</h3>
             <p>Please enter your Phone number</p>
             <div class="login-form-item">
               <i class="far fa-envelope"></i>
               <input
                 type='number'
                 pattern="[0-9]*"
                 class="form-control"
                 placeholder="Enter Number"
                 value={phoneno}
                 onChange={(e)=>setphonno(e.target.value)}
                
               />
             </div>
 
             <div class="row justify-content-center">
               <div class="col-lg-6">
                 <button class="create-btns"
                   style={{ backgroundColor: 'rgb(23, 88, 81)', transition: 'background-color 0.3s' }}
                   onClick={Checknumber} >
                   Continue
                 </button>
               </div>
             </div>
           </div>
         </div>
       </div>
       
     </div>
  }
  { otp&& <div class="container create-formcon">
     <div class="row justify-content-center" style={{ maxHeight: '444px' }}>
         <div class="col-lg-4 col-md-7 login-otp-height">
             <div class="loginotp-holder">
                 <h3>Enter OTP</h3>
                 <p>We have send OTP to your email. If you have not received OTP in your Inbox, Please check spam /
                     junk folder.</p>
                 <div class="login-form-item">
                     <i class="fas fa-dot-circle"></i>
                     <input type="text" class="form-control" placeholder="Enter Code"  />
                 </div>
 
                 <div class="row justify-content-center">
                     <div class="col-lg-6">
                         <button class="create-btns" >Continue</button>
                     </div>
                 </div>
             </div>
         </div>
     </div>
 </div>
  }
  </div>
 </>
  )
}

export default Loginpage