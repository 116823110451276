import React from 'react'

function Failed() {
  return (
    <div class="box"> 
 {/* <div class="success alert">
    <div class="alert-body">
      Success !
    </div>
  </div>
  */
 }
 <div class="error alert">
      <div class="alert-body">
       Session Closed
      </div>
  </div>
</div>
  )
}

export default Failed