import React from 'react'
import '../Style/Success.css'
function Successpage() {
  return (
    <div class="box"> 
  <div class="success alert">
    <div class="alert-body">
      Success !<br>
        
      </br>
      <span style={{color:'red'}}>Session Closed...!</span>
    </div>
  </div>
  { /* <div class="error alert">
      <div class="alert-body">
        Error !
      </div>
  </div>*/}
</div>
  )
}

export default Successpage